.gmcd-home {
  margin: 12px;
  h1 {
    text-align: center;
  }
  h4 {
    margin-right: 150px;
    margin-top: 40px;
    text-align: center;
    .login-button {
      padding: 6px 12px;
    }
  }
  .media {
    margin: 24px;
    padding-top: 24px;
    border-top: solid 3px #cdf;
    img {
      box-shadow: 0 -4px -4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .media-body {
      padding: 24px;
      ul {
        font-size: 24px;
        .details {
          color: #999;
          font-size: 18px;
        }
      }
    }
  }
}
