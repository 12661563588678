ol.progtrckr {
  list-style-type: none;
  padding: 0;
  li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
    span {
      padding: 0 1.5rem;
    }
    &.progtrckr-todo {
      color: silver;
      border-bottom: 4px solid silver;
      &:before {
        content: "\039F";
        color: silver;
        background-color: white;
        width: 1.2em;
        line-height: 1.4em;
      }
      &:hover:before {
        color: #ff4500;
      }
    }
    &.progtrckr-doing {
      color: black;
      border-bottom: 4px solid #CCCCCC;
      &:before {
        content: "\2022";
        color: white;
        background-color: #CCCCCC;
        width: 1.2em;
        line-height: 1.2em;
        border-radius: 1.2em;
      }
      &:hover:before {
        color: #ff4500;
      }
    }
    &.progtrckr-done {
      color: black;
      border-bottom: 4px solid #5cb85c;
      &:before {
        content: "\2713";
        color: white;
        background-color: #5cb85c;
        width: 1.2em;
        line-height: 1.2em;
        border-radius: 1.2em;
      }
      &:hover:before {
        color: #333;
      }
    }
    &:before {
      position: relative;
      bottom: -3.7rem;
      float: left;
      left: 50%;
    }
    &:after {
      content: "\00a0\00a0";
    }
  }
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

.footer-buttons {
  margin-top: 24px;
  margin-bottom: 24px;
}
.alert-hint {
  background-color: #eef9ff;
  border-color: #cef;
  color: #68a;
}
