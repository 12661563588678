@import '../../design/colors.scss';

.snowflake {
  & > div {
    padding: 8px 12px;
    border: solid 1px #ccc;
    overflow-y: auto;
    font-size: 14px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .step-3 , .step-5 , .step-7 {
    background-color: $success-bg-92;
    &:hover {
      background-color: $success-bg-85;
    }
  }
  .step-1 , .step-2 , .step-4 , .step-6 , .step-8 , .step-9 {
    background-color: $secondary-95;
    &:hover {
      background-color: $secondary-90;
    }
  }
  .step-0 , .step-10 , .step-99 {
    background-color: $gray-200;
    &:hover {
      background-color: $gray-300;
    }
  }
  ul, p {
    margin-bottom: .5rem;
  }
  h4 {
    font-size: 15px;
    color: #999;
  }
  h2 {
    font-size: 20px;
  }
  cite {
    display: inline-block;
    width: 100%;
    text-align: right;
    color: #999;
    font-size: 12px;
  }
}
