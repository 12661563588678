@import './colors.scss';

$btn-focus-width:             0;

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$nav-link-padding-y:                .9rem;

$navbar-light-color:                rgba($primary-text, .8);
$navbar-light-hover-color:          rgba($primary-text, 1);
$navbar-light-active-color:         rgba($primary-text, 1);
$navbar-light-disabled-color:       rgba($primary-text, .4);

$form-group-margin-bottom:          0;

$yiq-text-light: $gray-700 !default;

@import "node_modules/bootstrap/scss/bootstrap";

.btn-outline-primary:hover {
  background-color: $secondary-95;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
.btn-outline-secondary.disabled {
  background-color: $gray-400;
  opacity: 0.3;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary-text;
}
.form-group {
  margin-bottom: 8px;
}
.col-form-label {
  font-size: 85%;
  color: $gray-600;
  padding-top: 4px;
  padding-bottom: 0;
}

.nav-tabs {
  border-bottom-color: $gray-500;
  .nav-link {
    padding: 4px 12px;
  }
}
.nav-tabs .nav-link {
  border-top-color: $gray-200;
  border-left-color: $gray-200;
  border-right-color: $gray-200;
  border-bottom-color: $gray-500;
  &:hover {
    border-top-color: $gray-400;
    border-left-color: $gray-400;
    border-right-color: $gray-400;
  }
  &.active {
    border-top-color: $gray-500;
    border-left-color: $gray-500;
    border-right-color: $gray-500;
  }
  margin-right: 8px;
}
.tab-pane {
  padding: 8px;
  border-left: solid 1px $gray-500;
  border-right: solid 1px $gray-500;
  border-bottom: solid 1px $gray-500;
  min-height: 40vh;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.gmcd-select.Select--multi .Select-value {
  background-color: $gray-200;
  color: $gray-900;
  border-color: $gray-400;
  .Select-value-icon {
    background-color: theme-color-level("danger", -10);
    border-color: $gray-400;
  }
}

.entry-filter {
  padding: 4px;
  border: solid 1px $gray-400;
  margin-bottom: 2px;
  .remove-filter {
    color: theme-color-level("danger", -8);
    &:hover {
      color: theme-color-level("danger", -1);
    }
  }
  &.disabled {
    font-size: 85%;
    border-color: transparent;
  }
}
.md-self {
  color: #090;
  font-weight: bold;
}
.md-label {
  font-weight: bold;
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
}
.md-link {
  color: #009;
  font-weight: bold;
  cursor: pointer;
  white-space: pre;
  &:hover {
    text-decoration: underline;
  }
  &.bordered {
    border: solid 1px #ddd;
    padding: 4px;
    margin-right: 8px;
  }
  .fa {
    margin-right: 4px;
  }
}
.details-header {
  .toggle {
    color: $gray-400;
    margin-right: 12px;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
  .fa {
    margin-right: 8px;
  }
}
.markdown-header {
  position: relative;
  .toggle {
    color: $gray-400;
    cursor: pointer;
    border: solid 1px $gray-200;
    &:hover {
      color: $gray-700;
      border: solid 1px $gray-400;
    }
    .fa {
      margin: 0 4px;
    }
  }
}

.type-panel {
  &.expandable {
    h4 , .h4 {
      cursor: pointer;
    }
  }
  h4 , .h4 {
    &.collapsed {
      margin-bottom: 4px;
      border-bottom: solid 1px $gray-400;
    }
    &.expanded {
      margin-bottom: 0;
    }
    .fa {
      margin-right: 8px;
    }
  }
}
.char-imp {
  &-main {
    font-weight: 600;
    &.selected {
      font-weight: 900 !important;
    }
  }
  &-major {
    opacity: .8;
  }
  &-minor {
    opacity: .6;
  }
  &-NPC {
    opacity: .4;
  }
  &-undefined {
    color: #900;
  }
}
.char-att {
  &-good {
    background-color: #efe;
    &:hover {
      background-color: #cfc !important;
    }
    &.selected {
      background-color: #cfc !important;
      &:hover {
        background-color: #afa !important;
      }
    }
  }
  &-bad {
    background-color: #fee;
    &:hover {
      background-color: #fcc !important;
    }
    &.selected {
      background-color: #fcc !important;
      &:hover {
        background-color: #faa !important;
      }
    }
  }
}
.char-role {
  &-protagonist {
    color: #090;
  }
  &-antagonist {
    color: #900;
  }
  &-mentor {
    color: #009;
  }
  &-sidekick {
    color: #660;
  }
  &-logical {
    color: #066;
  }
  &-emotional {
    color: #606;
  }
}
textarea.form-control {
  padding: 0.25em 4px;
  border: solid 1px #ddbbcf;
  border-radius: 0;
  &:focus {
    color: #900;
    background-color: $secondary-95;
  }
}
.inline-help-btn {
  color: $secondary-80;
  cursor: help;
  &:hover {
    color: $secondary-50;
  }
}

.panel-toolbar {
  margin-bottom: 8px;
  .btn-group {
    margin-right: 12px;
  }
  .btn-sm , .btn-group-sm > .btn {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
    &.btn-outline-secondary:not(:disabled):not(.disabled) {
      &:hover {
        background-color: $secondary-90;
      }
      &.active {
        background-color: $secondary-80;
        &:hover {
          background-color: $secondary-70;
        }
      }
    }
  }
}

.color-swaths {
  margin-top: 0.5rem;
}
.text-sample {
  text-align: center;
  padding: 1rem;
  border: solid 1px #ccc;
  &:not(:first-child) {
    border-left: none;
  }
}

.primary-text {
  color: $primary-text;
  &-medium {
    color: $primary-text-medium;
  }
  &-light {
    color: $primary-text-light;
  }
}
.secondary-text {
  color: $secondary-text;
  &-medium {
    color: $secondary-text-medium;
  }
  &-light {
    color: $secondary-text-light;
  }
}

.success {
  &-text {
    color: $success-text;
  }
  &-medium {
    color: $success-medium;
  }
  &-light {
    color: $success-light;
  }
}
.warning {
  &-text {
    color: $warning-text;
  }
  &-medium {
    color: $warning-medium;
  }
  &-light {
    color: $warning-light;
  }
}
.danger {
  &-text {
    color: $danger-text;
  }
  &-medium {
    color: $danger-medium;
  }
  &-light {
    color: $danger-light;
  }
}

.background {
  &-sample {
    text-align: center;
    padding: 1rem;
  }
  &-gray {
    &-100 {
      background-color: $gray-100;
      color: $gray-900;
    }
    &-200 {
      background-color: $gray-200;
      color: $gray-900;
    }
    &-300 {
      background-color: $gray-300;
      color: $gray-900;
    }
    &-400 {
      background-color: $gray-400;
      color: $gray-900;
    }
    &-500 {
      background-color: $gray-500;
      color: $gray-900;
    }
    &-600 {
      background-color: $gray-600;
      color: $gray-100;
    }
    &-700 {
      background-color: $gray-700;
      color: $gray-100;
    }
    &-800 {
      background-color: $gray-800;
      color: $gray-100;
    }
    &-900 {
      background-color: $gray-900;
      color: $gray-100;
    }
  }
  &-primary {
    &-33 {
      background-color: $primary-33;
    }
    &-50 {
      background-color: $primary-50;
    }
    &-60 {
      background-color: $primary-60;
    }
    &-70 {
      background-color: $primary-70;
    }
    &-80 {
      background-color: $primary-80;
    }
    &-90 {
      background-color: $primary-90;
    }
    &-100 {
      background-color: $primary-100;
    }
  }
  &-secondary {
    &-50 {
      background-color: $secondary-50;
    }
    &-60 {
      background-color: $secondary-60;
    }
    &-70 {
      background-color: $secondary-70;
    }
    &-80 {
      background-color: $secondary-80;
    }
    &-90 {
      background-color: $secondary-90;
    }
    &-95 {
      background-color: $secondary-95;
    }
  }
}

.gmcd {
  .sdc-action-button {
    padding: 1px;
    border: none;
    &.action-create {
      color: $success-medium;
    }
  }
  .form-label-help {
    border-color: $secondary-80;
    background-color: $secondary-95;
  }
}
