@import "../../design/colors.scss";

.project-status {
  .missing {
    color: $danger-medium;
  }
  .locked {
    color: $warning-medium;
  }
  .unlocked {
    color: $success-medium;
  }
}
