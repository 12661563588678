.nv-chart {
  height: 100%;
  margin: 10px;
}
.bordered {
  border-bottom: solid 1px white;
}
h3 .btn {
  padding: 0 2px;
  border: solid 1px #ccc;
  min-width: 24px;
  margin-top: -2px;
  margin-left: 8px;
}
