$primary-hue   : 324;
$secondary-hue : 230;

$gray-hue    : $primary-hue;
$success-hue : 120;
$warning-hue :  50;
$danger-hue  :  10;
$violett-hue : 270;

$brand-color : hsl($primary-hue,33,50);

$primary : $brand-color;

$primary-text        : hsl($primary-hue, 80,20);
$primary-text-medium : hsl($primary-hue, 50,40);
$primary-text-light  : hsl($primary-hue, 20,60);

$secondary-text        : hsl($secondary-hue, 80,20);
$secondary-text-medium : hsl($secondary-hue, 50,40);
$secondary-text-light  : hsl($secondary-hue, 20,60);

$primary-33:  hsl($primary-hue, 33,50);
$primary-50:  hsl($primary-hue, 50,50);
$primary-60:  hsl($primary-hue, 60,50);
$primary-70:  hsl($primary-hue, 70,50);
$primary-80:  hsl($primary-hue, 80,50);
$primary-90:  hsl($primary-hue, 90,50);
$primary-95:  hsl($primary-hue, 95,50);
$primary-100: hsl($primary-hue,100,50);

$primary-bg-80: hsl($primary-hue,100,80);
$primary-bg-90: hsl($primary-hue,100,90);
$primary-bg-95: hsl($primary-hue,100,95);

$secondary-text-50: hsl($secondary-hue,50,50);
$secondary-text-60: hsl($secondary-hue,60,50);
$secondary-text-70: hsl($secondary-hue,70,50);

$secondary-50: hsl($secondary-hue,100,50);
$secondary-60: hsl($secondary-hue,100,60);
$secondary-70: hsl($secondary-hue,100,70);
$secondary-80: hsl($secondary-hue,100,80);
$secondary-90: hsl($secondary-hue,100,90);
$secondary-95: hsl($secondary-hue,100,95);

$violett-bg-80: hsl($violett-hue,100,80);
$violett-bg-90: hsl($violett-hue,100,90);
$violett-bg-95: hsl($violett-hue,100,95);

$white:    #fff !default;
$gray-100: hsl($gray-hue,20,98) !default;
$gray-200: hsl($gray-hue,17,93) !default;
$gray-300: hsl($gray-hue,15,89) !default;
$gray-400: hsl($gray-hue,13,83) !default;
$gray-500: hsl($gray-hue,11,71) !default;
$gray-600: hsl($gray-hue, 9,46) !default;
$gray-700: hsl($gray-hue, 9,31) !default;
$gray-800: hsl($gray-hue,10,23) !default;
$gray-900: hsl($gray-hue,11,15) !default;
$black:    #000 !default;

$success-text   : hsl($success-hue, 80,20);
$success-medium : hsl($success-hue, 50,40);
$success-light  : hsl($success-hue, 20,60);
$success-bg-85  : hsl($success-hue, 100,85);
$success-bg-90  : hsl($success-hue, 100,90);
$success-bg-92  : hsl($success-hue, 100,92);
$success-bg-95  : hsl($success-hue, 100,95);

$warning-text   : hsl($warning-hue, 80,20);
$warning-medium : hsl($warning-hue, 50,40);
$warning-light  : hsl($warning-hue, 20,60);

$danger-text    : hsl($danger-hue,  80,20);
$danger-medium  : hsl($danger-hue,  50,40);
$danger-light   : hsl($danger-hue,  50,60);

$danger-bg-60   : hsl($danger-hue,  100,60);
$danger-bg-70   : hsl($danger-hue,  100,70);
$danger-bg-80   : hsl($danger-hue,  100,80);
$danger-bg-90   : hsl($danger-hue,  100,90);
$danger-bg-95   : hsl($danger-hue,  100,95);
