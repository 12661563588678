@import '../design/colors.scss';

.navbar-gmcd {
  background-color: $secondary-95;
  border-color: $secondary-90;
  .navbar-brand {
    color: $brand-color;
  }
  a.navbar-brand {
    padding: 8px 12px;
  }
  .navbar-nav li a.active {
    color: $primary-text;
    background-color: $secondary-90;
  }
  .app-version {
    padding-top: 2px;
  }
  .nav-item.sdc-pomodoro-panel {
    width: 7rem;
    .pomodoro-time {
      font-size: 32px;
      &.plan {
        background-color: $secondary-95;
        border-color: $secondary-80;
      }
    }
  }
}

.login-button {
  border-color: $secondary-90;
}
