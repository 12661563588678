@import '../../design/colors.scss';

.gmcd-chart-box {
  height: 100%;
  border: solid 1px $secondary-90;
  padding-top: 35px;
  .box-header {
    background-color: #eee;
    border: solid 1px $secondary-90;
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    width: 100%;
    padding: 0 6px 0 10px;
    h3 {
      width: 100%;
      font-size: 20px;
      margin: 7px 0 5px;
      display: inline-block;
    }
  }
  .box-content {
    height: 100%;
    padding: 12px;
  }
  &.daily-progress .box-header h3 {
    margin-top: 5px;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  font-size: 85%;
  margin-top: -2px;
  .progress-date-selector {
    width: 100px;
    padding-bottom: 0;
    text-align: center;
  }
}
