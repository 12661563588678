.ReactTable .rt-th.left {
    text-align: left;
}
.ReactTable .rt-td.centered {
  text-align: center;
}
.ReactTable .rt-td.right {
  text-align: right;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  cursor: n-resize ! important;
}
.actions a , .actions span {
  color: #333;
  padding: 0;
  margin: 0 1px;
  opacity: 0.3;
}
.actions a:hover , .actions span:hover {
  color: #000;
  opacity: 1.0;
  background-color: #fff;
}
.ReactTable.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) .actions a ,
.ReactTable.-highlight .rt-tbody .rt-tr:hover:not(.-padRow) .actions span {
  opacity: 0.5;
}
.ReactTable.-highlight .rt-tbody .rt-tr.selected {
  font-weight: bold;
}
.ReactTable.-highlight .rt-tbody .rt-tr.locked {
  background-color: #fee;
  &:hover:not(.-padRow) {
    background-color: #edd;
  }
}
