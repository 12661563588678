@import '../../design/colors.scss';

.dashboard {
  .highlighted {
    background-color: #fee;
  }
  th.genre-selected {
    background-color: $secondary-90;
  }
  td.genre-selected {
    background-color: $secondary-95;
  }
  th.genre-planned {
    background-color: $violett-bg-90;
  }
  td.genre-planned {
    background-color: $violett-bg-95;
  }
}
