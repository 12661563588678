body {
  margin: 0;
  padding: 0;
  font-family: Convergence, sans-serif;
}

.btn-danger {
  background-color: #fee;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background-color: #fcc;
}
